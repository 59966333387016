import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=6665608e&"
import script from "./Catalog.vue?vue&type=script&lang=ts&"
export * from "./Catalog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLazyImage: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/app/LazyImage.vue').default,BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/base/Icon.vue').default,AppLazyHydrate: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/14/a/NuxtApp/components/app/LazyHydrate.vue').default})
